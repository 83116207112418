:root {
  --dark-blue: #000d62;
  --light-blue: #f2f4ff;
}

.App {
  padding: 20px;
}

.w100 {
  width: 100%;
}

.danger {
  color: #fe1a27;
}

.warning {
  color: #ffd900;
}

.ant-row {
  margin-bottom: 10px;
}

.m0 {
  margin: 0;
}

.mb20 {
  margin-bottom: 20px;
}

.mb100 {
  margin-bottom: 100px;
}

.ant-form-item-row, .ant-form-item {
  margin: 0;
}

.ant-form-item-explain-error {
  display: none;
}

.center {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* =============================================== */
.performance-review-form-comp .main-info label {
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 16px;
  min-width: 130px;
}

.main-info .ant-input {
  min-width: 300px;
}

.performance-review-form-comp .form-1 label {
  color: var(--dark-blue);
}

/* =============================================== */
.section-header-component {
  font-weight: 700;
  color: var(--dark-blue);
  border-bottom: 1px solid var(--dark-blue);
  margin-bottom: 10px;
  text-align: center;
}

/* =============================================== */
.title-subtitle-component {
  color: var(--dark-blue);
  margin-bottom: 10px;
}

.title-subtitle-component .title {
  font-weight: 700;
}

/* =============================================== */
.orange-wrapper {
  background-color: #fdeada;
  padding: 10px;
  margin-bottom: 10px;
}

/* =============================================== */
.recommended-rating-info {
  border: 1px solid black;
  padding: 10px;
}

/* =============================================== */
.performance-table {
  width: 100%;
}

.performance-table thead td, .performance-table-summary td {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  vertical-align: baseline;
}

.evaluation-comments-subtext {
  font-size: 12px;
}

.performance-table .column-1 {
  width: 50%;
}

.performance-table .column-2 {
  width: 15%;
}

.performance-table .column-3 {
  width: 35%;
}

.performance-table thead .column-1,
.performance-table .performance-table-summary .column-1,
.performance-table thead .column-2,
.performance-table thead .column-3 {
  border: 1px solid white;
  background-color: #ff3300;
}

/* .performance-table .column-2,
.performance-table .column-3 {
  border: 1px solid white;
  background-color: #f2f4ff;
} */

.performance-table thead .column-1 .ant-input {
  max-width: 300px;
}

.performance-table .orange-wrapper {
  margin: 0;
  border: 1px solid white;
  color: var(--dark-blue);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.performance-table .blue-wrapper {
  margin: 0;
  border: 1px solid white;
  color: var(--dark-blue);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.performance-table .orange-wrapper {
  margin: 0;
  border: 1px solid white;
  color: var(--dark-blue);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.performance-table .category {
  font-size: 16px;
}

.performance-table .ant-row {
  margin: 0;
}

.last-row td {
  border: none;
  background-color: #fdeada;
}

.performance-table .column-2, .performance-table .column-3 {
  padding: 10px;
  background-color: var(--light-blue);
}


/* =============================================== */
.development-table {
  width: 100%;
}

.development-table thead td, .development-table-summary td {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  vertical-align: baseline;
}

.evaluation-comments-subtext {
  font-size: 12px;
}

.development-table .column-1 {
  width: 30%;
}

.development-table .column-2 {
  width: 70%;
}

.development-table thead .column-1,
.development-table .development-table-summary .column-1,
.development-table thead .column-2,
.development-table thead .column-3 {
  border: 1px solid white;
  background-color: #ff3300;
}

/* .development-table .column-2,
.development-table .column-3 {
  border: 1px solid white;
  background-color: #f2f4ff;
} */

.development-table thead .column-1 .ant-input {
  max-width: 300px;
}

.development-table .orange-wrapper {
  margin: 0;
  border: 1px solid white;
  color: var(--dark-blue);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.development-table .blue-wrapper {
  margin: 0;
  border: 1px solid white;
  color: var(--dark-blue);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.development-table .orange-wrapper {
  margin: 0;
  border: 1px solid white;
  color: var(--dark-blue);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.development-table .category {
  font-size: 16px;
}

.development-table .ant-row {
  margin: 0;
}

.last-row td {
  border: none;
  background-color: #fdeada;
}

.submit-button, .submit-button:hover {
  background-color: #ff3300 !important;
}

.development-table tbody .column-1 {
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fdeada;
  color: var(--dark-blue);
}

.development-table .column-2 {
  padding: 10px;
  background-color: var(--light-blue);
}

.evaluation-comments-subtext {
  font-weight: normal;
}

.development-plan-table {
  width: 100%;
}

.development-plan-table thead td, .development-plan-table-summary td {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  vertical-align: baseline;
}

.development-plan-table thead .column-1,
.development-plan-table .development-plan-table-summary .column-1 {
  border: 1px solid white;
  background-color: #ff3300;
}

.development-plan-table tbody tr:nth-child(n+2) .column-1,
.development-plan-table tbody tr:nth-child(n+2) .column-2,
.development-plan-table tbody tr:nth-child(n+2) .column-3 {
  padding: 10px;
  background-color: var(--light-blue);
}

.development-plan-table tbody tr:first-child td {
  color: var(--dark-blue);
  background-color: #fdeada;
  padding: 10px;
}
